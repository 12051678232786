<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit property close payment') + ` id: ${form.id}` : $t('Create property close payment') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :horizontal="{ input: 'col-sm-3' }" name="month_id" label="Month" v-model="form.month_id" :options="available_months" :isValid="isValid('month_id')" :errors="getErrors('month_id')" @select="month => onChangeMonth(month.id)" />
              <ACheckBox label="Last day" placeholder="Configurar el último día de cada mes." v-model="form.last_day" @change="onChangeLastDay" />
              <!--AInput v-if="form.month_id && !form.last_day" :horizontal="{ input: 'col-sm-2' }" name="close_payments" label="Maximum date" v-model="form.close_payments" :isValid="isValid('close_payments')" :errors="getErrors('close_payments')" /-->
              <!--AInput v-if="show_calendar" :horizontal="{ input: 'col-sm-2' }" name="close_payments" label="Maximum date" type="date" data-date-format="YYYYMMDD" :min="date_selected.format(formats.DATEFORMAT)" :max="max_date.format(formats.DATEFORMAT)" v-model="form.close_payments" :isValid="isValid('close_payments')" :errors="getErrors('close_payments')" /-->
              <AInput v-if="!form.last_day" :horizontal="{ input: 'col-sm-2' }" name="close_payments" label="Maximum date" :placeholder="`1-31 | ${formats.DATEFORMAT}`" v-model="form.close_payments" :isValid="isValid('close_payments')" :errors="getErrors('close_payments')" />
              <ACheckBox label="Generate interests" placeholder="Generar intereses sobre saldos no pagados antes de la fecha." v-model="form.generate_interests" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import parametersMixin from '@/app/views/_mixins/parameters-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'PropertyClosePaymentForm',
  mixins: [formMixin, parametersMixin],
  computed: {
    date_selected() {
      if (!this.form.month_id) return undefined
      const date = '' + this.form.month_id
      return this.$moment('20' + date.substr(0, 2) + '-' + date.substr(2, 2) + '-01')
    },
    max_date() {
      if (!this.date_selected) return undefined
      return this.$moment(this.date_selected)
        .add(2, 'months')
        .endOf('month')
    },
    show_calendar() {
      //return this.form.close_payments && this.form.month_id && !this.form.last_day
      return this.form.month_id && !this.form.last_day
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, close_payments: '' },

      // Extra
      lists: {
        months: []
      },

      // Helpers
      alerts: [],
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/property_close_payments' + (self.form.id ? `/${self.form.id}/edit` : '/create') + (this.$route.query.duplicate ? '?duplicate=true' : ''))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      if (('' + data.property_close_payment.close_payments).length === 8) data.property_close_payment.close_payments = this.$moment(data.property_close_payment.close_payments, 'YYYYMMDD').format(this.formats.DATEFORMAT)

      this.form = data.property_close_payment ? data.property_close_payment : { id: 0 }
      this.lists = data._lists || {}

      this.setAvailableMonths('property_close_payment', this.$route.query.duplicate)
      if (this.$route.query.duplicate) this.setDuplicate(this.available_months)
      this.parseView()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'PropertyClosePayment']: options })
    },

    // Helpers
    onChangeLastDay() {
      console.log(this.form.last_day)
      this.$nextTick(() => {
        this.setLastDay(this.form.month_id, this.form.last_day)
      })
    },
    onChangeMonth(value) {
      this.setLastDay(value, this.form.last_day)
    },
    setLastDay(date, checked) {
      // TODO: On backend ?
      this.form.close_payments = ''

      date = '' + (date || '')
      let close_payments = ''
      if (date.length === 4) {
        date = '20' + date.substr(0, 2) + '-' + date.substr(2, 2) + '-01'
        const new_date = this.$moment(date).endOf('month')
        if (checked) close_payments = +new_date.format('D')
        else close_payments = new_date.format(this.formats.DATEFORMAT)
      }
      this.$nextTick(() => {
        //this.form.close_payments = close_payments
        this.$set(this.form, 'close_payments', close_payments)
      })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      if (!this.form.last_day) {
        const error = { close_payments: [`Fecha no válida. 1-31 o ${this.formats.DATEFORMAT}`] }

        if (!isNaN(this.form.close_payments)) {
          if (this.form.close_payments < 1 || this.form.close_payments > 31) return this.setErrors(error)
        } else {
          // TODO: Use input format ?
          const to_date_format = this.$moment(this.form.close_payments).format(this.formats.DATEFORMAT)
          if (!this.form.close_payments || this.form.close_payments.length < 10 || !this.$moment(to_date_format, this.formats.DATEFORMAT, true).isValid()) return this.setErrors(error)

          this.form.close_payments = to_date_format.replaceAll('-', '')
        }
      }

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/property_close_payments', self.form) // TODO: on service ?
        .then(response => {
          self.showAlert(`Property close payment ${self.form.id ? 'saved' : 'created'}.`)
          if (this.$route.query.duplicate) self.$router.replace('/admin/parameters/property_close_payments')
          else {
            self.$router.replace(`/admin/parameters/property_close_payments/${response.data.property_close_payment.id}/edit`).catch(() => {})
            self.parseData(response.data)
          }
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the property close payment.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
